<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('payment_history') }}
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-dialog
            ref="dateDialog"
            v-model.trim="modalData"
            :return-value.sync="start_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="start_date"
                :label="$t('date')"
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model.trim="start_date"
              class=""
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalData = false"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dateDialog.save(start_date)"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model.trim="payment_type_id"
            :items="statusList"
            :label="$t('package_type')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :server-items-length="totalDataTableList"
        :options.sync="options"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.payment_id`]="{ index }">
          {{ segmentId+ index + 1 }}
        </template>
        <template v-slot:[`item.payment_type_id`]="{ item }">
          <span v-if="$i18n.locale == 'th'">{{ statusList[item.payment_type_id].th }}</span>
          <span v-if="$i18n.locale == 'en'">{{ statusList[item.payment_type_id].en }}</span>
        </template>
        <template v-slot:[`item.payment_date`]="{ item }">
          <ConverseDate :date="item.payment_date"></ConverseDate>
        </template>
        <template v-slot:[`item.payment_cost`]="{ item }">
          {{ item.payment_cost | formatPrice }}
        </template>
        <template v-slot:[`item.payment_evidence`]="{ item }">
          <v-btn
            icon
            color="primary"
            @click="showImage(item.payment_evidence)"
          >
            <v-icon>{{ icons.mdiImageSearchOutline }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.payment_create`]="{ item }">
          <ConversDateTime :datetime="item.payment_create"></ConversDateTime>
        </template>
        <template v-slot:[`item.payment_status_id`]="{ item }">
          <v-chip
            v-if="item.payment_status_id == '1'"
            color="warning"
            small
            class="v-chip-light-bg warning--text"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiAlertOutline }}
            </v-icon> {{ $t("waiting_check") }}
          </v-chip>
          <v-chip
            v-if="item.payment_status_id == '2'"
            color="success"
            small
            class="v-chip-light-bg success--text"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiCheck }}
            </v-icon> {{ $t("checked") }}
          </v-chip>
          <v-chip
            v-if="item.payment_status_id == '3'"
            color="error"
            small
            class="v-chip-light-bg error--text"
          >
            <v-icon
              small
              class="me-1"
            >
              {{ icons.mdiClose }}
            </v-icon>  {{ $t("incorrect_information") }}
          </v-chip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isShowImage"
      max-width="600"
    >
      <v-img
        contain
        :src="imageUrl"
      ></v-img>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  mdiCheck, mdiAlertOutline, mdiClose, mdiImageSearchOutline,
} from '@mdi/js'
import usePaymentHistory from './usePaymentHistory'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import { formatPrice } from '@/plugins/filters'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConverseDate,
    Pagination,
    ConversDateTime,
  },
  filters: {
    formatPrice,
  },
  setup() {
    const isShowImage = ref(false)
    const imageUrl = ref('')
    const showImage = image => {
      imageUrl.value = image
      isShowImage.value = true
    }
    const {
      dataTableList,
      totalDataTableList,
      options,
      segmentId,
      footer,
      loading,
      totalPage,
      start_date,
      payment_type_id,
      statusList,
      modalData,
      tableColumns,
      fetchDataTable,
    } = usePaymentHistory()

    return {
      fetchDataTable,
      imageUrl,
      isShowImage,
      modalData,
      dataTableList,
      totalDataTableList,
      options,
      totalPage,
      segmentId,
      footer,
      loading,
      start_date,
      payment_type_id,
      statusList,
      tableColumns,
      showImage,
      icons: {
        mdiCheck, mdiAlertOutline, mdiClose, mdiImageSearchOutline,
      },
    }
  },

}
</script>
