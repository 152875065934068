import package_type from '@/@fake-db/data/package_type.json'
import paymentHistory from '@/api/systemSetting/paymentHistory'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function usePaymentHistory() {
  // filters valiable
  const start_date = ref('')
  const payment_type_id = ref('')
  const statusList = ref(package_type.data)
  const modalData = ref(false)

  // datatable variables
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const options = ref({})
  const segmentId = ref(0)
  const totalPage = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const loading = ref(false)
  const tableColumns = [
    {
      text: '#',
      align: 'start',
      value: 'payment_id',
      width: 80,
    },
    {
      text: i18n.t('a_number'),
      value: 'payment_number',
      width: 130,
    },
    {
      text: i18n.t('type'),
      value: 'payment_type_id',
      width: 110,
    },
    {
      text: i18n.t('package'),
      value: 'package_name',
      width: 120,
    },
    {
      text: i18n.t('transfer_to_bank'),
      value: 'bank_name',
      width: 160,
    },

    {
      text: i18n.t('transfer_date'),
      value: 'payment_date',
      width: 120,
    },
    {
      text: i18n.t('transfer_time'),
      value: 'payment_time',
      width: 120,
    },
    {
      text: i18n.t('amount'),
      value: 'payment_cost',
      width: 120,
      align: 'end',
    },
    {
      text: i18n.t('evidence'),
      value: 'payment_evidence',
      width: 120,
      align: 'center',
    },
    {
      text: i18n.t('date_transfer'),
      value: 'payment_create',
      width: 150,
    },
    {
      text: i18n.t('status'),
      value: 'payment_status_id',
      width: 100,
    },
    {
      text: i18n.t('transferor'),
      value: 'payment_by',
      width: 230,
    },
  ]
  onMounted(() => {
    fetchDataTable()
  })
  const { paymentHistoryList } = paymentHistory

  const fetchDataTable = () => {
    const body = {
      start_date: start_date.value,
      payment_type_id: payment_type_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }

    loading.value = true
    paymentHistoryList(body).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      loading.value = false
    })
  }

  watch([start_date, payment_type_id, options], () => {
    fetchDataTable()
  })

  return {
    tableColumns,
    dataTableList,
    totalDataTableList,
    options,
    fetchDataTable,
    segmentId,
    footer,
    totalPage,
    loading,
    start_date,
    payment_type_id,
    statusList,
    modalData,
  }
}
