import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const paymentHistoryList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'paymentsms/list',
      body,
    })
    .then(res => (res || []))
    .catch(err => {
      console.log('get payment sms package history list err : ', err)

      return {}
    })

  return response
}
export default { paymentHistoryList }
